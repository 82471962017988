﻿@imageHeight:265px;

.ui-widget-overlay{
    cursor:pointer;
    cursor:zoom-out;
    cursor:-webkit-zoom-out;
    background:black;
    opacity:0.7;
    z-index: 5001!important;
}

.ui-dialog{
    cursor:pointer;
    cursor:zoom-out;
    cursor:-webkit-zoom-out;
    z-index: 5002!important;
    border: none;
    border-radius: 0;
    background:transparent;
    text-align:center;

    .popup-image{
        margin-top:5%;
    }

    .ui-dialog-titlebar{
        display:none;
    }
}

.carousel-inner{
    &.product-image-carousel-inner{
        img{
            max-height:@imageHeight;
        }
        height:auto;
        cursor:pointer;
        cursor:zoom-in;
        cursor: -webkit-zoom-in;
    }
}

.product-image-link{
    cursor:pointer;
    cursor:zoom-in;
    cursor: -webkit-zoom-in;
}

.carousel-indicators{
    &.product-image-carousel-indicators{
        position:relative;
        bottom: 0;
        background: black;
        margin-bottom: 0;
        width: 100%;
        margin: 0;
        left:0;
        margin-top: 15px;

        li{  
            img{
                border: solid 2px #cccccc;
            }     
            &:nth-child(3n+2) {
                div{
                    margin:0 2px;
                }
            }     
            &:nth-child(3n+1) {
                div{
                    margin-right:4px;
                }
            } 
            &:nth-child(3n+3) {
                div{
                    margin-left:4px;
                }
            } 

            margin: 0;
            display:list-item;
            width:33.3%;
            height:100%;
            border-radius: 0;
            border:none;
            padding: 0;
            overflow: hidden;
            float:left;
            opacity:.7;

            &.active, &:hover{
                opacity:1;
            }
        }
    }
}


.phone-icon {
    padding-left: 3px;
    padding-top: 2px;
}

.email-icon {
    font-size: 12px;
    padding-left: 3px;
}

.address-icon {
    float: left;
    height: 50px;
    padding-left: 2px;
    padding-top: 2px;
}

.btn-primary.remove-confirm {
    padding: 0 5px;
    margin: 0;
}

.ask {
    overflow: hidden;
    width: 100%;
    float: left;
    cursor: pointer;
}

.confirm-yes, .confirm-no {
    overflow: hidden;
    width: 0;
    float: left;
    cursor: pointer;
}

.confirm-yes button, .confirm-yes a {
    border-radius: 4px 0px 0px 4px;
}

.confirm-no button, .confirm-no a {
    border-radius: 0px 4px 4px 0px;
}

.slider-confirmation-container {
    overflow: hidden;

    div {
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }
}

.expire-date {
    padding-top: 4px;
    &.date-is-expired {
        color: #ff0000;
    }
}

#new-payment-method-container{
    .form-group{
        margin:5px;
         
        .bank-id-field, .account-number-field,
        .account-type-field, .personal-id-field,
        .branch-name-field, .branch-street-field,
        .branch-street-2-field, .branch-street-3-field,
        .branch-city-field, .branch-state-field,
        .branch-country-field, .branch-postal-code-field,
        .origin-field, .consent-date-field, .payment-code {
            margin: 5px 0;
        }
    }
}

#order-summary{
    .consent-date-field{
        .datepicker{
            padding: 6px 12px;
        }
    }
}

.edit-expiration {
    padding: 0 !important;
    margin: 0 !important;
}

.expiration-date {
    float: left;
    padding-right: 10px;
    margin-top: 3px;
}

.confirm-no, .confirm-yes, .ask, .slider-confirmation-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

body.fixed-nav.hide-navigation{
    padding-top:0;
    margin: 50px;
    margin-top:0;
    font-size: 10px!important;
}


.row.prime.hide-navigation{
    padding-top:0;
    margin-top:0;
}

.new-page-print{
    page-break-before: always;
}

input.quantity {
    width: 65px;
    padding-left: 7px;
    padding-right: 7px;
}

/* 
  Allow angular.js to be loaded in body, hiding cloaked elements until 
  templates compile.  The !important is important given that there may be 
  other selectors that are more specific or come later and might alter display.  
  See: https://docs.angularjs.org/api/ng/directive/ngCloak
 */
[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}